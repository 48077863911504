/* custom-image-gallery.css */

/* Flèche gauche */
.image-gallery-icon.image-gallery-left-nav {
    color: white; /* Modifier la couleur du texte */
  }
  
  /* Flèche droite */
  .image-gallery-icon.image-gallery-right-nav {
    color: white; /* Modifier la couleur du texte */
  }


  
  