#title-event{
  display: flex;
  justify-content: center;
}

#title-event h2{
  color: white;
  font-size: 2.5rem;
  border-bottom: 3px solid #d00000;
  text-align: center;
}

.card-box{
  background-color: #191919;
  margin-bottom: 20px;
  border-radius: 5px;
  
  align-items: center;
  display: flex;
  flex-direction: column;
}





.event-button{
  border-radius: 5px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  font-weight: 900;
  margin-top: 18px;
}
.event-button:hover{
  background-color: #d00000;
  color: black;
  
  transition: all 1s ;
}


#event-container{
  display: flex;
  flex-direction: column;
 align-items: center;

}


.photo-container {
  font-size: 1.5em;
  background-color: #d00000;
  color: white;
  padding: 20px;
  text-align: center;
  grid-area: photo;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-container {
  margin: 10px;
  display: grid;
  grid-template-areas: 
    'photo info';
  grid-template-columns: 150px 1fr;
  width: 500px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  line-height: 1.75em;
}
.info-container {
  padding: 20px;
  grid-area: info;
}
.event-name {
  font-weight: bold;
  padding-bottom: 5px;
}
.event-location {
  font-weight: 300;
}
.day {
  font-weight: 300;
}
.month {
  font-weight: 600;
}

@media( max-width: 600px ) {
  .card-container {
    width: 90%;
  }
}