.navbar {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    width: 100%;
    height: 110px;
  }
  
  .logo {
    font-size: 24px;
    font-weight: bold;
  }
  
  .nav-links {
    list-style: none;
    display: flex;
  }
  
  .nav-links li {
    margin-right: 10px;
    font-weight: bold;
  }
  
  .nav-links a {
    color: black;
    text-decoration: none;
  }
  
  .nav-links a:hover {
    text-decoration: none;
    color: #d00000;
  }
  
  .social-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    margin-right: 18px;
    border-radius: 50%;
    border: 2px solid black;
    transition: background-color 0.5s ease-out;
    transition: transform 0.2s ease-in-out;
  }
  
  .social-icon .logo-icon {
    color: black;
  }
  
  .social-icon:hover {
    background-color: #d00000;
    transform: translateY(-5px);
  }
  .social-link{
    display: flex;
  }
  @media screen and (max-width: 752px) {
   
    .social-icon{
      width: 30px;
      height: 30px;
      margin: 5px auto;
    }
    .nav-links{
      font-size: 10px;
    }
    .logo img{
      width: 100px;
      height: 100px;
    }
    .navbar{
      flex-direction: column;
      height: 187px;
      
    }
    .logo{
      height: 66px;
    }
    .social-link{
      gap: 20px;
    }
    

    
  }
  @media screen and (max-width: 415px) {
   
  
    .nav-links{
     margin: 0;
    }
   

    
  }
  