.teaser-container {
    display: flex;
    justify-content: center;
    background-color: black;
    width: 100%;
    
  }
  
  .teaser-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%; /* Modifier cette ligne */
    margin: 60px 20px;
    width: 100%;
  }
  
  
  .teaser-section-title {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    border-bottom: 3px solid #d00000;
   
    color: white;
  }
  
  .teaser-player-wrapper {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin: 120px;
  
   
   
  }
  
  .teaser-container {
    flex-basis: 50%;
  }
  
  .teaser-description {

    max-width: 515px;
    width: 100%;
    padding: 20px;
    margin-left: 50px;
    color: white;
  }
  
  .teaser-description-title {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #d00000;
  }
  
  .teaser-description-text {
    font-size: 1.5rem;
    line-height: 1.5;
  }
  .teaser-section-title span {
    color: #d00000;
  }
  
  .teaser-player {
    width: 100%;
    border-radius: 10px;
   
  }
  @media screen and (max-width: 1240px) {
    .teaser-player-wrapper{
      flex-direction: column-reverse;
      gap: 0;
      margin: auto;
    }
    .teaser-description{
      margin: 20px;
      padding: 0px;
    }
    .teaser-description-text{
      font-size: 20px;
      text-align: center;
      margin: 20px;
    }
    .teaser-description-title{
      font-size: 30px;
      text-align: center;
    }
  }
  