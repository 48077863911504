.footer{
    width: 100%;
    text-align: center;
    background-color: white;
    display: flex;
    justify-content: space-between;
    



}



.logo img{
    width: 200px;
    height: 200px;



}
.social-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 55px;
  }
  
  .social-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    margin: 0 10px;
    border-radius: 50%;
    border: 2px solid black;
    transition: background-color 0.5s ease-out;
    transition: transform 0.2s ease-in-out;
  }
  
  .social-icon .logo-icon {
    color: black;
  }
  
  .social-icon:hover {
    background-color: #d00000;
    transform: translateY(-5px);
    
  }
  .copyright{

    color: black;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 50px;
  }
  @media screen and (max-width: 788px) {
    .footer{
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
    .social-icons{
      margin: 0 auto;
      gap: 20px;
    }
    .copyright{
      margin: 20px auto;
    }
    
  }