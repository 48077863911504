.show-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../assets/show-bg2.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; /* Ajuster la position de l'image */
  background-color: rgba(0, 0, 0, 0.956); /* Opacité réglable ici */
  width: 100%;
  height: 1000px;
  flex-direction: column;
}

/* Votre autre CSS reste inchangé */


/* Votre autre CSS reste inchangé */


.show-title {
  text-align: center;
  color: white; /* Ajout pour assurer que le texte est blanc */
}

.show-content {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  flex-direction: row-reverse;
}

.show-cover-container {
  flex: 0 1 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.show-cover-container img {
  max-width: 100%;
  height: auto;
  box-shadow: -5px 1px 9px 2px rgba(0, 0, 0, 0.81);
  -webkit-box-shadow: -5px 1px 9px 2px rgba(0, 0, 0, 0.81);
  -moz-box-shadow: -5px 1px 9px 2px rgba(0, 0, 0, 0.81);
}

.show-description {
  text-shadow: 2px 2px 1px black;
  flex: 0 1 50%;
  margin-bottom: auto;
}

.show-title h2 {
  display: flex;
  font-size: 2.5rem;
  margin-bottom: 121px;
  border-bottom: 3px solid #d00000;
  font-weight: bold;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.show-description p {
  font-size: 1.5rem;
  line-height: 1.5;
  margin-bottom: 40px;
  color: white;
}

.show-description span {
  color: #d00000;
}

.text-show {
  display: flex;
  font-size: 2.5rem;
  margin-bottom: 18px;
  color: white;
  border-bottom: 3px solid #d00000;
  font-weight: bold;
  max-width: max-content;
}

@media screen and (max-width: 1400px) {
  .show-content {
      flex-direction: column-reverse;
  }
}

@media screen and (max-width: 970px) {
  .show-content {
      flex-direction: column-reverse;
  }
  .show-cover-container {
      border-right: 0px;
      padding-right: 0px;
      margin-right: 0px;
  }
  .show-description p {
      font-size: 20px;
      text-align: center;
      margin: 20px;
  }
  .show-section h2 {
      margin-bottom: 55px;
  }
}
