.contact-section {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 800px;
	background-color: #191919;
	width: 100%;

  }
  .contact-text{
	text-align: center;
	
	max-width: 515px;
    width: 100%;
    padding: 20px;
	
    color: white;
  }
  .contact-text a{
	text-decoration: none;
	color : #d00000
  }
  .contact-text a:hover{
	color: #d00000;

  }
  
  .contact-content {
	display: flex;
	justify-content: space-between;

	width: 90%;
	max-width: 1200px;
  }
  .contact-content h2{
	font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    border-bottom: 3px solid #d00000;
	display: inline-block;
    margin-bottom: 20px;
    color: white;
}
  
  
  .contact-img-container {
	flex: 0 1 40%;
	margin-right: 2rem;
  }
  
  .contact-img-container img {
	width: 100%;
	height: auto;
	box-shadow: -5px 1px 9px 2px rgba(0,0,0,0.81);
-webkit-box-shadow: -5px 1px 9px 2px rgba(0,0,0,0.81);
-moz-box-shadow: -5px 1px 9px 2px rgba(0,0,0,0.81);
  }
  
  .contact-form {
	flex: 0 1 50%;
	display: flex;
	flex-direction: column;
  }
  
  .contact-form h2 {
	font-size: 2rem;
	margin-bottom: 2rem;
	color: white;
	text-align: center;
  }
  
  .contact-form form {
	display: flex;
	flex-direction: column;
  }
  
  .contact-form label {
	font-size: 1.2rem;
	margin-bottom: 0.5rem;
	color: white;
  }
  
  .contact-form input,
  .contact-form textarea {
	padding: 0.5rem;
	margin-bottom: 1rem;
	border: none;
	border-radius: 5px;
	resize: none;
	
  }
  
  .contact-form input:focus,
  .contact-form textarea:focus {
	outline: none;
	box-shadow: 0 0 5px #191919;
  }
  
  .contact-form button {
	padding: 0.5rem 1rem;
	background-color: black;
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
  }
  
  .contact-form button:hover {
	background-color: #d00000;
  }
  .contact-text p{
	font-size: 1.5rem;
    line-height: 1.5;
  }
  @media screen and (max-width: 1210px) {
	.contact-content h2{
		font-size: 1.5rem;
	}
	.contact-content p{
		font-size: 1rem;
	}
	
  }
  @media screen and (max-width: 939px) {
	.contact-content{
		flex-direction: column-reverse;
		justify-content: flex-end;
		gap: 70px;
	}
	.contact-img-container{
		margin: 0 auto;
	}
	.contact-img-container img{
		margin: 0 auto;
		max-width: 380px;
	}
	.contact-section{
		align-items: normal;

	}
	.contact-text{
		margin: 20px auto;
		padding: 0;
	}
	
  }
  