.thevoice-player-container {
    display: flex;
    justify-content: center;
    background-color: #191919;

  }
  
  
  .thevoice-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%; /* Modifier cette ligne */
    margin: 60px 20px;
    width: 100%;
  }
  
  
  .thevoice-section-title {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    border-bottom: 3px solid #d00000;
    margin-bottom: 20px;
    color: white;
  }
  
  .thevoice-player-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
  
    gap: 57px;
    
  }
  
  .thevoice-container {
    flex-basis: 50%;
   margin-right: 136px;
  }
  
  .thevoice-description {
    max-width: 515px;
    width: 100%;
    
    
    color: white;
    word-wrap: break-word; /* Ajoutez cette ligne */
  }
  
  .thevoice-description-title {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #d00000;
  }
  
  .thevoice-description-text {
    font-size: 1.5rem;
    line-height: 1.5;
    overflow-wrap: break-word; /* Ajoutez cette ligne */
  }
  
  .thevoice-player {
    max-width: 850px;
    width: 100%;
    border-radius: 10px;
    margin: 20px;
  }
  @media screen and (max-width: 1240px) {
    .thevoice-player-wrapper{
      flex-direction: column-reverse;
      gap: 0;
    }
    .thevoice-player{
      margin: 0;
    }
    .thevoice-description{
      padding: 0px;
      margin: 20px;
    }
    .thevoice-container{
      margin: 0;
    }
    .thevoice-description-title{
      font-size: 30px;
      text-align: center;
    }
    .thevoice-description-text{
      font-size: 20px;
      text-align: center;
    }
    
  }