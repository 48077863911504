.timeline-container {
  margin: 0 auto;
  max-width: 800px;
  text-align: center;
  width: 100%;
}
.timeline-container span{
  color: #d00000;
}
.timeline-container h3{
  color: white;
}

.timeline {
  list-style: none;
  padding: 0;
  position: relative;
  margin-top: 40px;
  width: 100%;
}
.timeline h3{
  color: #d00000;
}

.timeline:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #ccc;
  left: 50%;
  margin-left: -1px;
}

.timeline-item {
  margin-bottom: 50px;
  position: relative;
}

.timeline-content {
  background-color: #f4f4f4;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  box-shadow: 0 3px 10px rgba(0,0,0,.2);
 
}

.timeline-title {
  font-size: 24px;
  margin-top: 0;
  color: #d00000;
}

.timeline-description {
  font-size: 16px;
  margin-bottom: 0;
  color: black;
}
.timeline-container h1{
  font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    border-bottom: 3px solid #d00000;
    margin-bottom: 20px;
    color: white;
    display: inline-block;
    
}

