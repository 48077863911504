.back-to-top-button {
position: fixed;
bottom: 20px;
right: 20px;
background-color: #333;
color: #fff;
padding: 10px 20px;
border: none;
border-radius: 4px;
cursor: pointer;
}
.back-to-top-button:hover{
   background-color: #d00000;
}