.profil {
    background-color: black;
    
    width: 100%;
    display: flex;
    align-items: center;
    
    overflow: hidden;
    
    
  }
  

.profil img {
    
    left: 0;
    animation: slide-in-left 3s ease-out, fade-in 3s ease-in;
    width: 100%;
    max-width: 1200px;
}
.profil h1{
    color: white;
    animation-name: write;
    animation-duration: 5s;
    animation-fill-mode: forwards;
    font-family: "Kalista", sans-serif;
   
    white-space: nowrap;
   
   
    font-size: 180px;
    
    transform: rotate(355deg);
   margin-top: 50px;
  
    

}
.profil h2 {
  color: white;
  font-size: 25px;
  font-weight: bold;
  
  text-align: center;
  animation-name: fade-in;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  }
.description-hero{
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

@keyframes slide-in-left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes write {
    from {  opacity: 0; }
    to {  opacity: 1; }
  }

  @media screen and (max-width: 2026px) {
    .profil h1{font-size: 130px;}
    .profil h2{font-size: 25px;}
    
  }
  @media screen and (max-width: 1633px) {
    .profil h1{font-size: 105px;}
    .profil h2{font-size: 25px;}
    
  }
  @media screen and (max-width: 1483px) {
    .profil h1{font-size: 90px;}
    .profil h2{font-size: 25px;}
    
  }
  @media screen and (max-width: 1407px) {
    .profil h1{font-size: 70px;}
    .profil h2{font-size: 20px;}
    
  }

  @media screen and (max-width: 1385px) {
    .profil h1{font-size: 60px;}
    .profil h2{font-size: 20px;}
    
  }
  @media screen and (max-width: 1270px) {
    .profil{
      flex-direction: column;

      
    }
    .profil h1{
      position: static;
      margin: 0;
    }
    .profil h2{
      position: static;
      margin: 20px;
      text-align: center;
    }
    
  }
  @media screen and (max-width: 523px) {
    .profil h1{
      font-size: 50px;
    }
    .profil h2{
      font-size: 20px;
    }
    
  }
